import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import axios from 'axios';

const ProfileProductions = () => {
  const [user, setUser] = useState(null);
  const [productions, setProductions] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const showMore = (posid) => {
    // Implement the showMore function logic here
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`/api/checkuser`);
        console.log("Check User Response:", response.data);
        if (!response.data.status) {
          const message = `Der er sket en teknisk fejl. Prøv igen senere.`;
          setMessage(message);
          setError(true);
          setLoading(false);
          return;
        } else if (response.data.status) {
          const userHolder = response.data;
          setUser(userHolder);

          if (userHolder.newId) {
            try {
              const productionsResponse = await axios.get(`/api/profilesproductions/${userHolder.id}`);
              setProductions(productionsResponse.data.results);
              setLoading(false);
            } catch (error) { 
              console.error('Error fetching productions:', error);
              setMessage(`Der er sket en teknisk fejl. Prøv igen senere.`);
              setError(true);
              setLoading(false);
            }
          } else if (!userHolder.newId) {
            setMessage("Det ser ud til, at du er gået forkert!");
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Profilen kunne ikke indlæses pga. en teknisk fejl", error);
        setMessage(`Der er sket en teknisk fejl. Prøv igen senere.`);
        setError(true);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{message}</div>;
  }

  return (
    <div class="background-grey pb-5">
    <div>
    <Container className="div-height-hundred-vh">
    <div class="row underborder-black mb-3">
              <div class="col-8">
                <h1 className="mt-5">
              Dine produktioner
                </h1>
              </div>
    </div>
      {productions.map((production, index) => (
        <div key={production.productionid}>
          <h2>{production.productionTitle}</h2>
          <p>Roller</p> 
          {production.positions.map((position) => (
              <div key={position.id} className="mb-2">
                <p>Skills: {position.skills}</p>
                <p>Job Description: {position.jobDescription}</p>
                <p>Paid: {position.paid ? 'Yes' : 'No'}</p>
              </div>
            ))}
          <button className="button-message-read-more-decline mb-2" onClick={() => showMore(production.productionid)}>
            Læs mere
          </button>
        </div>
      ))}
      </Container>
    </div>
    </div>
  );
};

export default ProfileProductions;