import React from "react";
import "./App.css";
import NavBar from "./components/navbar";
import FrontPage from "./components/frontpage";
import Footer from "./components/footer";
import { Routes, Route} from "react-router-dom";
import ProjectsFeed from "./components/projectsfeed";
import ProfilesFeed from "./components/profilesfeed";
import Profile from "./components/profile";
import ProfileSignup from "./components/profilesignup";
import ProfileLogin from "./components/profilelogin";
import ProfileEdit from "./components/profileedit";
import ProfileEditCv from "./components/profileeditcv";
import ProfileEditInfo from "./components/profileeditinfo";
import ProfileEditImage from "./components/profileeditimage";
import ProfileContact from "./components/profilecontact/profilecontact";
import VerifyEmail from "./components/verifyemail";
import Messages from "./components/messages";
import ProjectsCreate from "./components/projectscreate";
import TemporaryLanding from "./components/temporarylanding/temporarylanding";
import DeleteMailList from "./components/deletemaillist";
import Project from "./components/project/project";
import ProjectApply from "./components/projectapply/projectapply";
import ProfileProductions from "./components/profileComponents/profile-productions/profile-productions";

function App() {
  return (
    <div className="App">
      <div class="div-height-hundred-vh">
      {/* <NavBar/>  */}
      <Routes>
          <Route path="/" element={<TemporaryLanding />} /> 
          <Route path="/login" element={<ProfileLogin/>} />
          {/* <Route path="/slet-mailliste-tilmelding/:email/:secret" element={<DeleteMailList />} />*/}
         <Route path="/produktioner" element={<ProjectsFeed />} /> 
          <Route path="/opret-produktion" element={<ProjectsCreate />} />
          <Route path="/produktioner/:id" element={<Project />} />
          <Route path="/rediger-profil/produktioner/" element={<ProfileProductions />} />
          <Route path="/login" element={<ProfileLogin/>} />
          <Route path="/produktioner/:id/kontakt" element={<ProjectApply />} />
          {/* <Route path="/filmarbejdere" element={<ProfilesFeed />} /> */}

          {/* <Route path="/filmarbejdere/:id" element={<Profile />} />
          <Route path="/filmarbejdere/:id/kontakt" element={<ProfileContact />} />
          <Route path="/tilmeld" element={<ProfileSignup />} />
         
          <Route path="/rediger-profil" element={<ProfileEdit/>} />
          <Route path="/rediger-profil/erfaringer" element={<ProfileEditCv/>} />
          <Route path="/rediger-profil/info" element={<ProfileEditInfo/>} />
          <Route path="/rediger-profil/profil-billede" element={<ProfileEditImage/>} />
          <Route path="/bekræft-profil/:token" element={<VerifyEmail />} />
          <Route path="/beskeder" element={<Messages />} />  */}
      </Routes>
      </div>
       {/* <Footer />    */}
         </div>
  );
}
export default App;
